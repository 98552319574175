import 'core-js/stable';
import '@babel/polyfill';
import camelCase from './util/camelCase';
import Bowser from 'bowser';
import { listen } from 'quicklink';
import { guess } from 'guess-webpack/api';

const config = require('../config.json');

// import Array of all Routes we want
import routes from './routes';

// Fire up service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    if (process.env.WP_ENV != 'development') {
      navigator.serviceWorker.register('/sw.js')
      .catch(registrationError => {
        console.log('SW registration failed: ', registrationError);
      });
    }
  });
} else {
  console.log('Service worker is not active on your navigator');
}

// Wait page is loaded
window.onload = async () => {

  if (process.env.WP_ENV != 'development') {
    // Init QuickLink
    listen({
      el: document.querySelector('.main'),
      throttle: 5,
      ignores: [
        /\/api\/?/,
        uri => uri.includes('.zip'),
        (uri, elem) => elem.hasAttribute('noprefetch'),
      ],
    });

    // Init GuessJS
    if (config.GOOGLE_ANALYTICS_VIEW_ID && config.GOOGLE_ANALYTICS_VIEW_ID !== '') {
      guess();
    }
  }

  // Check Browser and import good polyfill
  const browser = Bowser.getParser(window.navigator.userAgent);
  const browserName = browser.getBrowserName().toLowerCase().replace(' ', '_');

  try {
    await import(/* webpackChunkName: "polyfill", webpackPreload: true */ `./polyfills/${browserName}`)
  } catch(error) {
    console.log('No polyfill for this browser');
  }


  // import common.js for all page
  const { default: common } = await import(/* webpackChunkName: "common", webpackPreload: true */ './routes/common');

  // Fire init from common.js
  common.init();

  // Check class on body to determine the current page
  document.body.className
  .toLowerCase()
  .replace(/-/g, '_')
  .split(/\s+/)
  .map(camelCase)
  .forEach(async (className) => {

    if (routes.includes(className)) {

      // Import right .js for the current page
      const { default: _ } = await import(/* webpackChunkName: "[request]", webpackPrefetch: true */ `./routes/${className}`);

      // Fire init & finalize from current page .js
      _.init();
      _.finalize();
    }

  });

  // Fire finalize from common.js
  common.finalize()
}
